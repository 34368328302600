import { Component, ErrorHandler, OnInit } from '@angular/core';
import { CommonService } from './services/common.service';
import { BaseComponent } from './components/base.component';
import { AppViewModel } from './models/view/app.viewmodel';
import { LogHandlerService } from './services/log-handler.service';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from './internal-components/footer/footer.component';
import { LoaderComponent } from './internal-components/loader/loader.component';
import { TopNavComponent } from './internal-components/top-nav/top-nav.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-root',
    standalone:true,
    imports: [
        CommonModule,
        RouterOutlet,
        TopNavComponent,
        FooterComponent,
        LoaderComponent,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: LogHandlerService
        },
    ],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent extends BaseComponent<AppViewModel> implements OnInit {


  constructor(commonService: CommonService, logService: LogHandlerService ) {
    super(commonService, logService)
  }


  async ngOnInit() {
  //  alert(JSON.stringify(this.getUserAgentInfo()));


      // localStorage.setItem('First','use');
  }
  // getIpAddress(): Observable<any> {
    // return this.http.get('https://api64.ipify.org?format=json'); // IPv6 & IPv4
  // }




}
