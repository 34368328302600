import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class FooterComponent {
  protected _commonService: CommonService;
  constructor(commonService: CommonService) {
    this._commonService = commonService;
  }
  getCurrentYear(): number {
    return new Date().getFullYear();
  }
}
