<header class="header_area" #stickyMenu [class.sticky]="_commonService.layoutViewModel.stickyMenu"
    *ngIf="!_commonService.layoutViewModel.showAdmintoptNav">

    <nav class="navbar navbar-expand-lg navbar-dark menu_one menu_five" aria-label="Offcanvas navbar large">

        <div class="container-fluid">


            <a class="navbar-brand sticky_logo" routerLink="/home">
                <img src="/assets/top-nav/renoLogoWhite.webp" class="img-fluid" alt="Reno Softwares"
                    *ngIf="!_commonService.layoutViewModel.stickyMenu">
                <img src="/assets/top-nav/renoLogoBlack.webp" class="img-fluid" alt="Reno Softwares"
                    *ngIf="_commonService.layoutViewModel.stickyMenu">
            </a>


            <a class="navbar-brand sticky_logo_Microsoft position-absolute top-1 m-0"
                href="https://appsource.microsoft.com/en-us/marketplace/partner-dir/a8ca6562-9e15-4f65-82e2-eb97061103c0/overview"
                target="_blank">
                <img src="/assets/top-nav/MicrosoftPartnerwhite.webp" class="img-fluid" alt="MicrosoftPartner"
                    *ngIf="!_commonService.layoutViewModel.stickyMenu">
            </a>
            <a class="navbar-brand sticky_logo_Microsoft position-absolute top-1 m-0"
                href="https://appsource.microsoft.com/en-us/marketplace/partner-dir/a8ca6562-9e15-4f65-82e2-eb97061103c0/overview"
                target="_blank">
                <img src="/assets/top-nav/MicrosoftPartner.webp" class="img-fluid" alt="MicrosoftPartner"
                    *ngIf="_commonService.layoutViewModel.stickyMenu">
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar2"
                aria-controls="offcanvasNavbar2" aria-label="Toggle navigation">
                <i class="bi bi-list"></i>
                <span class="navbar-toggler-icon d-none">
                </span>
            </button>


            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar2"
                aria-labelledby="offcanvasNavbar2Label">

                <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasNavbar2Label">
                        <img src="/assets/top-nav/renoLogoBlack.webp" class="img-fluid" alt="logo">
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close">
                    </button>
                </div>

                <div class="offcanvas-body">

                    <ul class="navbar-nav menu w_menu me-auto ms-auto menu-ul">

                        <li class="nav-item dropdown submenu mega_menu mega_menu_two" routerLink="/home"
                            routerLinkActive="active">
                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show">
                                Home
                            </a>
                        </li>

                        <li class="nav-item dropdown submenu" routerLink="/about" routerLinkActive="active">
                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show">
                                About
                            </a>
                        </li>

                        <li class="dropdown submenu nav-item" routerLink="/products" routerLinkActive="active">
                            <a class="dropdown-toggle nav-link" role="button" data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show">
                                Products
                            </a>
                        </li>

                        <li class="nav-item dropdown submenu mega_menu" routerLink="/projects"
                            routerLinkActive="active">
                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show">
                                Projects
                            </a>
                        </li>

                        <li class="nav-item dropdown">

                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown"
                                aria-expanded="false">
                                Services
                            </a>

                            <div class="dropdown-menu services_dropdown">

                                <div class="row pb-0">

                                    <div class="col-lg-3 pe-0" routerLink="/solution-engineering">

                                        <h5 class="d-inline-block"> Solution Engineering </h5>

                                        <i class="bi bi-caret-down float-end" id="solution-engineering"
                                            (click)="toggleDropdown($event)"
                                            *ngIf="_commonService.layoutViewModel.solutionEngineering == 'servicesListDropdown'"></i>

                                        <i class="bi bi-caret-up float-end" (click)="toggleDropdown($event)"
                                            *ngIf="_commonService.layoutViewModel.solutionEngineering == 'toggleSolutionEngineering'"></i>

                                        <ul class="list-unstyled"
                                            [ngClass]="[_commonService.layoutViewModel.solutionEngineering]">
                                            <li>
                                                <i class="bi bi-arrow-right-short"></i>
                                                Enterprise Software Development
                                            </li>
                                            <li>
                                                <i class="bi bi-arrow-right-short"></i>
                                                Custom Software Solutions
                                            </li>
                                            <li> <i class="bi bi-arrow-right-short"></i> Web Development & Design </li>
                                            <li> <i class="bi bi-arrow-right-short"></i> Mobile development </li>
                                            <li> <i class="bi bi-arrow-right-short"></i> Cloud applications </li>
                                            <li> <i class="bi bi-arrow-right-short"></i> User-Interface/User-Experience
                                            </li>
                                            <li> <i class="bi bi-arrow-right-short"></i> VOIP/PBX Solutions
                                            </li>
                                            <li> <i class="bi bi-arrow-right-short"></i> Quality Assurance </li>
                                        </ul>

                                    </div>

                                    <div class="col-lg-3 pe-0" routerLink="/strategy">

                                        <h5 class="d-inline-block"> Strategy </h5>

                                        <i class="bi bi-caret-down float-end" id="strategy"
                                            (click)="toggleDropdown($event)"
                                            *ngIf="_commonService.layoutViewModel.strategy == 'servicesListDropdown'"></i>

                                        <i class="bi bi-caret-up float-end" (click)="toggleDropdown($event)"
                                            *ngIf="_commonService.layoutViewModel.strategy == 'toggleStrategy'"></i>

                                        <ul class="list-unstyled" [ngClass]="[_commonService.layoutViewModel.strategy]">
                                            <li>
                                                <i class="bi bi-arrow-right-short"></i>
                                                Digital transformation Consulting
                                            </li>
                                            <li> <i class="bi bi-arrow-right-short"></i> Research & development </li>
                                            <li> <i class="bi bi-arrow-right-short"></i> Technical Consulting </li>
                                            <li> <i class="bi bi-arrow-right-short"></i> Customer Experience </li>
                                        </ul>

                                    </div>

                                    <div class="col-lg-3 pe-0" routerLink="/project-management">

                                        <h5 class="d-inline-block"> Project Management </h5>

                                        <i class="bi bi-caret-down float-end" id="project-management"
                                            (click)="toggleDropdown($event)"
                                            *ngIf="_commonService.layoutViewModel.projectManagement== 'servicesListDropdown'"></i>

                                        <i class="bi bi-caret-up float-end" (click)="toggleDropdown($event)"
                                            *ngIf="_commonService.layoutViewModel.projectManagement == 'toggleProjectManagement'"></i>

                                        <ul class="list-unstyled"
                                            [ngClass]="[_commonService.layoutViewModel.projectManagement]">
                                            <li> <i class="bi bi-arrow-right-short"></i> Budgeting & Cost control </li>
                                            <li> <i class="bi bi-arrow-right-short"></i> Planning & Resources </li>
                                            <li> <i class="bi bi-arrow-right-short"></i> Release Management </li>
                                            <li> <i class="bi bi-arrow-right-short"></i> Client & Vendor </li>
                                            <li> <i class="bi bi-arrow-right-short"></i> Agile & Scrum </li>
                                        </ul>

                                    </div>

                                    <div class="col-lg-3 pe-0" routerLink="/microsoft-365">

                                        <h5 class="d-inline-block"> Microsoft 365 Services </h5>

                                        <i class="bi bi-caret-down float-end" id="microsoft365"
                                            (click)="toggleDropdown($event)"
                                            *ngIf="_commonService.layoutViewModel.microsoft365 == 'servicesListDropdown'"></i>

                                        <i class="bi bi-caret-up float-end" (click)="toggleDropdown($event)"
                                            *ngIf="_commonService.layoutViewModel.microsoft365 == 'toggleMicrosoft365'"></i>

                                        <ul class="list-unstyled"
                                            [ngClass]="[_commonService.layoutViewModel.microsoft365]">
                                            <li>
                                                <i class="bi bi-arrow-right-short"></i> SharePoint Deployment &
                                                Support
                                            </li>
                                            <li>
                                                <i class="bi bi-arrow-right-short"></i> Microsoft Teams Deployment &
                                                Training
                                            </li>
                                            <li>
                                                <i class="bi bi-arrow-right-short"></i> Licensing & Subscription
                                                Management
                                            </li>
                                            <li>
                                                <i class="bi bi-arrow-right-short"></i> Data Security & Compliance
                                                Services
                                            </li>
                                            <li> <i class="bi bi-arrow-right-short"></i> Administration & Support </li>
                                            <li> <i class="bi bi-arrow-right-short"></i>Microsoft 365 Migration
                                                Solutions
                                            </li>
                                            <li> <i class="bi bi-arrow-right-short"></i> Azure Information Protection
                                            </li>
                                            <li> <i class="bi bi-arrow-right-short"></i> Data loss Protection</li>
                                        </ul>

                                    </div>

                                </div>

                                <div class="row pt-0">
                                    <div class="col-lg-3 pe-0" routerLink="/ai-services">

                                        <h5 class="d-inline-block"> Artificial Intelligence </h5>
                                        <i class="bi bi-caret-up float-end" (click)="toggleDropdown($event)"
                                            id="ai-close"
                                            *ngIf="_commonService.layoutViewModel.aiServices == 'toggleAiServices'"></i>
                                        <i class="bi bi-caret-down float-end" id="ai-services"
                                            (click)="toggleDropdown($event)"
                                            *ngIf="_commonService.layoutViewModel.aiServices == 'servicesListDropdown'"></i>

                                        <ul class="list-unstyled"
                                            [ngClass]="[_commonService.layoutViewModel.aiServices]">
                                            <li>
                                                <i class="bi bi-arrow-right-short"></i>
                                                Deep Learning
                                            </li>
                                            <li> <i class="bi bi-arrow-right-short"></i> Optical Character Recognition
                                                (OCR)</li>
                                            <li> <i class="bi bi-arrow-right-short"></i> Natural Language Processing
                                                (NLP) </li>
                                            <li> <i class="bi bi-arrow-right-short"></i> Speech Processing</li>
                                        </ul>

                                    </div>
                                    <div class="col-lg-3 pe-0" routerLink="/cyber-security">

                                        <h5 class="d-inline-block"> Cybersecurity <br> & Data Protection </h5>

                                        <i class="bi bi-caret-down float-end" id="cyber-security"
                                            (click)="toggleDropdown($event)"
                                            *ngIf="_commonService.layoutViewModel.cyberSecurity == 'servicesListDropdown'"></i>

                                        <i class="bi bi-caret-up float-end" (click)="toggleDropdown($event)"
                                            *ngIf="_commonService.layoutViewModel.cyberSecurity == 'toggleCyberSecurity'"></i>

                                        <ul class="list-unstyled"
                                            [ngClass]="[_commonService.layoutViewModel.cyberSecurity]">
                                            <li>
                                                <i class="bi bi-arrow-right-short"></i>
                                                Network, Endpoint & Cloud security
                                            </li>
                                            <li> <i class="bi bi-arrow-right-short"></i> Vulnerability Management </li>
                                            <li> <i class="bi bi-arrow-right-short"></i> Data Encryption </li>
                                            <li> <i class="bi bi-arrow-right-short"></i> Access Control </li>
                                        </ul>

                                    </div>

                                    <div class="col-lg-3 pe-0" routerLink="/software-maintenance">

                                        <h5 class="d-inline-block"> Software Maintenance <br> & Upgrades </h5>

                                        <i class="bi bi-caret-down float-end" id="software-maintenance"
                                            (click)="toggleDropdown($event)"
                                            *ngIf="_commonService.layoutViewModel.softwareMaintenance == 'servicesListDropdown'"></i>

                                        <i class="bi bi-caret-up float-end" (click)="toggleDropdown($event)"
                                            *ngIf="_commonService.layoutViewModel.softwareMaintenance == 'toggleSoftwareMaintenance'"></i>

                                        <ul class="list-unstyled"
                                            [ngClass]="[_commonService.layoutViewModel.softwareMaintenance]">
                                            <li>
                                                <i class="bi bi-arrow-right-short"></i>
                                                Performance Optimization & Monitoring
                                            </li>
                                            <li>
                                                <i class="bi bi-arrow-right-short"></i>
                                                Third-Party Integration Updates
                                            </li>
                                            <li>
                                                <i class="bi bi-arrow-right-short"></i>
                                                Database Maintenance
                                            </li>
                                        </ul>

                                    </div>

                                    <div class="col-lg-3 pe-0" routerLink="/data-driven-marketing">

                                        <h5 class="d-inline-block"> Data-Driven Marketing </h5>

                                        <i class="bi bi-caret-down float-end" id="data-driven"
                                            (click)="toggleDropdown($event)"
                                            *ngIf="_commonService.layoutViewModel.dataDriven == 'servicesListDropdown'"></i>

                                        <i class="bi bi-caret-up float-end" (click)="toggleDropdown($event)"
                                            *ngIf="_commonService.layoutViewModel.dataDriven == 'toggleDataDriven'"></i>

                                        <ul class="list-unstyled"
                                            [ngClass]="[_commonService.layoutViewModel.dataDriven]">
                                            <li>
                                                <i class="bi bi-arrow-right-short"></i>
                                                Data Analytics & Business Intelligence
                                            </li>
                                            <li>
                                                <i class="bi bi-arrow-right-short"></i>
                                                SEO & Digital Marketing
                                            </li>
                                        </ul>

                                    </div>

                                </div>

                            </div>

                        </li>

                        <!-- <li class="nav-item dropdown submenu " routerLink="/blogs" routerLinkActive="active">
                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show">
                                Blogs
                            </a>
                        </li> -->

                        <li class="nav-item dropdown submenu" routerLink="/careers" routerLinkActive="active">
                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show">
                                Careers
                            </a>
                        </li>

                        <li class="nav-item dropdown submenu mega_menu" routerLink="/contact" routerLinkActive="active">
                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show">
                                Contact
                            </a>
                        </li>

                    </ul>

                    <!-- <a class="btn_get btn_get_radious menu_cus menu_custfive" routerLink="sign-up" data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"> Sign-Up/Sign-In </a> -->

                </div>

            </div>


        </div>

    </nav>

</header>